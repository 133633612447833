<template>
	<v-sheet>
		<h4 class="mb-4"> Results by Theme</h4>
		<p>The following table shows {{organisation.name}}'s results aggregated by the Digital Maturity Assessment's main themes (Readiness, Capabilities, Infrastructure). You can sort the information in the table by clicking on any column header. An option to download the information shown is provided below. 			</p>
		<v-data-table :headers="themeHeaders" :items="themeItems"></v-data-table>
		<export :columns="themeHeaders" :rows="themeItems" name="dma-scores-by-theme" text="Download"></export>

		<h4 class="mb-4 mt-9"> Results by Section</h4>
		<p>The following table shows {{organisation.name}}'s results for each section of the Digital Maturity Assessment. Note: Not all sections may have been presented to each organisation. You can sort the information in the table by clicking on any column header. An option to download the information shown is provided below. </p>

		<v-data-table :headers="sectionHeaders" :items="sectionItems"></v-data-table>
		<export :columns="sectionHeaders" :rows="sectionItems" name="dma-scores-by-section" text="Download"></export>
	</v-sheet>
</template>

<style lang="less"></style>

<script>
import Export from "@c/downloads/Export";
export default {
	name: "Scores",
	props: {
		id: { type: String },
	},
	components: {
		Export,
	},
	data: () => {
		return {};
	},
	computed: {
		themes() {
			let themes = this.$store.getters["themes/options"];
			return Object.fromEntries( themes.map( theme => [theme.id, theme])); 
		},
		sectionHeaders() {
			return [
				{ text: "Section", value: "section" },
				{ text: "Theme", value: "theme" },
				{ text: "Status", value: "status" },
				{ text: "Score", value: "score" },
			];
		},
		themeHeaders() {
			return [
				{ text: "Theme", value: "theme" },
				{ text: "Score", value: "score" },
			];
		},
		order() {
			return this.$store.getters.organisationResponses(this.id)
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		organisation(){
			return this.$store.state.organisations.data[this.id];
		},
		sections() {
			return this.$store.state.sections.data;
		},
		myResponses(){
			const self = this;
			return this.order
				.filter((item) => self.responses[item.response]).map( item => {
					return self.responses[item.response];
				}) 
		},
		results() {
			const self = this;
			return self.myResponses.flatMap((response) => {
					return this.mwsurveyutils.processResponse(response);
				})
				.filter((exists) => exists);
		},
		sectionItems() {
			const self = this;
			let sections = Object.keys(self.sections);
			return sections.map((section) => {
				let results = self.results.filter((r) => r.section == section);
					results = results.filter((r) => r.answer);
					let scores = results.map((r) => r.score);
					let score = Math.floor(self.mwutils.average(scores));
					let theme = self.themes[self.sections[section].theme];
					let responses = self.myResponses.filter(a => a.section == section );
					let status = responses.map( response => response.status).join(", ")
					status = this.mwutils.prettyPrint(status, "TITLE");
					return {
						section: self.sections[section].name,
						status: status,
						theme: theme ? theme.name : "",
						score,
					};
				});
		},
		themeItems(){
			const self = this;
			let themes = Object.values(self.themes).map( t => {
				return t.name;
			})
			let scores = themes.map( theme => {
				let scores = self.sectionItems.filter( item => item.theme == theme );
				scores = scores.map( item => item.score );
				let score = self.mwutils.average(scores);
				score = score.toFixed()
				return { theme, score }
			})
			return scores 
		}
	},
};
</script>
