<template>
	<v-row class="mt-4 mb-4">
		<v-col cols="12" sm="6">
			<v-row>
				<v-col cols="12" sm="6">
					<number-tile
						:number="completion"
						text="Completion Rate"
					></number-tile>
				</v-col>
				<v-col cols="12" sm="6">
					<number-tile
						:number="count('users')"
						text="Colleagues"
					></number-tile>
				</v-col>
				<v-col cols="12" sm="6">
					<number-tile :number="count('polls')" text="Polls"></number-tile>
				</v-col>
				<v-col cols="12" sm="6">
					<number-tile
						:number="delegations"
						text="Delegations"
					></number-tile>
				</v-col>
				<v-col cols="12" sm="6">
					<number-tile
						:number="count('staffSurveyResponses')"
						text="Staff Survey Responses"
					></number-tile>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" sm="6">
			<organisation-profile :id="id"></organisation-profile>
		</v-col>
	</v-row>
</template>
<script>
import NumberTile from "@c/ui/NumberTile.vue";
import OrganisationProfile from '@c/management/OrganisationProfile.vue';

export default {
	name: "OrganisationStatistics",
	props: {
		id: { type: String },
	},
	computed: {
		organisation() {
			return this.$store.state.organisations.data[this.id];
		},
		assessment() {
			let id = this.$store.getters.organisationAssessment(this.id);
			return this.$store.state.assessments.data[id];
		},
		organisationResponses(){
			const self = this;
			return self.$store.getters.organisationResponses(this.id).map((item) => {
				return this.$store.state.sectionResponses.data[item.response];
			});
		},
		aa(){
			const self = this;
			return self.$store.getters.organisationResponses(this.id).map((item) => {
				return self.sections[item.section].name + ", " +  this.$store.state.sectionResponses.data[item.response] + ", " + item.response;
			});
		},
		sectionResponses() {
			let id = this.assessment.id;
			let responses = this.$store.state.sectionResponses.data;
			responses = Object.values(responses);
			return responses.filter( f => f.assessment == id )
		},
		staffSurveyResponses() {
			let id = this.id;
			let responses = this.$store.state.staffSurveyResponses.data;
			responses = Object.values(responses);
			return responses.filter( f => f.organisation == id )
		},
		delegations(){
			return this.sectionResponses.filter( a => a.status == "delegated" ).length;
		},
		polls() {
			let id = this.assessment.id;
			let polls = this.$store.state.polls.data;
			polls = Object.values(polls);
			return polls.filter( u => u.assessment == id )
		},
		users() {
			let id = this.id;
			let users = this.$store.state.users.data;
			users = Object.values(users);
			return users.filter( u => u.organisations && u.organisations.includes(id) )
		},
		sections() {
			return this.$store.state.sections.data
			// return this.$store.getters.organisationResponses(this.id);
		},
		completion() {
			const self = this;
			let rates = self.organisationResponses.map((r) => r.completion_rate);
			let rate = self.mwutils.average(rates).toFixed();
			return rate + "%";
		},
	},
	components: {
		OrganisationProfile,
		NumberTile
	},
	methods: {
		count(a) {
			let count = 0;
			let values = this[a];
			if (values) {
				if (Array.isArray(values)) {
					count = values.length;
				} else {
					count = Object.keys(values).length;
				}
			}
			return count;
		},
	},
	created(){
		this.$store.dispatch("staffSurveyResponses/fetchAndAdd", {clauses: {where: [ ["organisation", "==", this.id]]}})
	},
};
</script>
