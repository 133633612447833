<template>
	<v-card flat v-if="organisation" color="#F5F4F4" text>
		<v-card-title>Organisation Details</v-card-title>
		<v-card-text>
			<p class="mb-5">
				{{ organisation.address }}<br />
				{{ organisation.city }}<br />
				{{ organisation.postcode }}<br />
				{{ organisation.phone }}<br />
				{{ organisation.url }}<br />
				<span v-if="leadRespondent"
					>{{ leadRespondent.displayName }}
					<a :href="`mailto:${leadRespondent.email}`">{{
						leadRespondent.email
					}}</a></span
				>
			</p>
			<v-chip
				small
				v-for="service in organisation.service"
				:key="service"
				color="primary"
				class="mr-2"
			>
				{{ services[service] }}
			</v-chip>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" class="mb-2" @click="sendEmail"
				>Send Reminder <v-icon class="ml-2">mdi-email</v-icon></v-btn
			>
		</v-card-actions>
		<v-snackbar color="primary" v-model="sent">Reminder Sent</v-snackbar>
	</v-card>
</template>
<script>
export default {
	name: "Profile",
	props: {
		id: { type: String },
	},
	data: ( ) => {
		return {
			sent: false
		}
	},
	computed: {
		organisation() {
			return this.$store.state.organisations.data[this.id];
		},
		leadRespondent() {
			return this.$store.getters.organisationLeadRespondent(this.id);
		},
		services() {
			return Object.fromEntries(
				this.$store.getters["categoryOptions/services"].map((s) => {
					return [s.id, s.name];
				})
			);
		},
	},
	methods: {
		sendEmail() {
			const self = this;
			self.$store.dispatch("emails/reminder", {
				userId: this.leadRespondent.id,
				orgId: this.id,
			}).then( () => {
				self.sent = true;
			});
		},
	},
};
</script>
