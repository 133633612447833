<template>
	<v-card tile class="text-center mb-2 pa-4 white--text tile" :dark="dark" :color="color" :style="{ height: height }">
		<v-card-text>
			<div class="tile-number">{{ number }}</div>
			{{ text }}
		</v-card-text>
	</v-card>
</template>

<style lang="less">
.tile-number {
	font-weight: 900;
	font-size: 2.4em;
	width: 100%;
	margin-bottom: 12px;
}
.v-card.tile {
	.v-card__text {
		vertical-align: middle !important;
	}
}
</style>

<script>
export default {
	name: "NumberTile",
	props: {
		number: { type: [String, Number] },
		text: { type: String },
		height: { type: String, default: "auto" },
		dark: { type: Boolean, default: true },
		color: { type: String, default: "green" },
	},
};
</script>
