<template>
	<v-sheet flat class="mt-5">
		<loading v-if="loading"></loading>
		<div v-else>
			<v-sheet>
				<h4 class="mb-4">Results by Question</h4>
				<p>The table below shows {{ name }}'s responses and corresponding scores for each question in the Digital Maturity Assessment.</p>
				<p>You can sort the information in the table by clicking on any column header. An option to download the information shown is provided below.</p>
				<v-data-table :headers="headers" :items="items" item-key="uid"></v-data-table>
				<export :columns="headers" :rows="items" name="dma-data" text="Export dataset"></export>
			</v-sheet>
		</div>
	</v-sheet>
</template>

<style lang="less"></style>

<script>
import Export from "@c/downloads/Export";
import Loading from "@c/ui/Loading";
export default {
	name: "Data",
	props: {
		id: { type: String },
	},
	components: {
		Loading,
		Export,
	},
	data: () => {
		return {
			loading: true,
		};
	},
	computed: {
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		name() {
			let org = this.$store.state.organisations.data[this.id];
			return org ? org.name : "";
		},
		order() {
			return this.$store.getters.organisationResponses(this.id);
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		sections() {
			return this.$store.state.sections.data;
		},
		headers() {
			return [
				{ text: "Section", value: "section" },
				{ text: "Theme", value: "theme" },
				{ text: "Question", value: "question" },
				{ text: "Service", value: "service" },
				{ text: "Answer", value: "processed_answer" },
				{ text: "Score", value: "score" },
			];
		},
		results() {
			const self = this;
			return this.order
				.filter((item) => self.responses[item.response])
				.flatMap((item) => {
					let response = self.responses[item.response];
					return this.mwsurveyutils.processResponse(response);
				})
				.filter((exists) => exists);
		},
		items() {
			const self = this;
			return self.results.map((response) => {
				let r = { ...response };
				r.uid = `${r.section}-${r.response}-${r.question}`;
				let section = self.sections[r.section];
				let theme = self.categoryOptions[section.theme];
				let category = self.categoryOptions[r["service"]];
				let questionText = self.$store.getters["questions/getQuestionTexts"](r.question);
				r.section = section ? section.name : "";
				r.theme = theme ? theme.name : "";
				r.question = questionText ? questionText.string : "";
				r.service = category ? category.name : "";
				return r;
			});
		},
	},
	watch: {
		responses: {
			immediate: true,
			handler() {
				if (this.responses && Object.keys(this.responses).length) {
					this.loading = false;
				}
			},
		},
	},
};
</script>
