<template>
	<v-sheet v-if="organisation">
		<p>
			<v-btn color="primary" @click="back">{{$t("buttons.back")}}</v-btn>
		</p>
		<h2>Organisation Overview - {{ organisation.name }}</h2>
		<p>You can find an overview over {{organisation.name}}'s activity below. </p>
		<organisation-stats :id="id"></organisation-stats>
		<scores :id="id"></scores>
		<data-table :id="id"></data-table>
	</v-sheet>
</template>

<script>
import DataTable from "@c/results/Data";
import Scores from "@c/results/Scores";
import OrganisationStats from "@c/results/OrganisationStats";
export default {
	name: "OrganisationDetail",
	props: {
		id: { type: String },
	},
	computed: {
		organisation() {
			return this.$store.state.organisations.data[this.id];
		},
	},
	components: {
		DataTable,
		OrganisationStats,
		Scores
	},
	methods: {
		back(){
			this.$router.go(-1)
		}
	},
	created(){
		if( !this.id ){
			this.$router.replace("/")
		}
	}
};
</script>
